/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'

import { useDispatch } from 'react-redux'
import { GatsbyImage } from 'gatsby-plugin-image'
import useProductOverHeadImage from '~/hooks/components/use-product-overhead-image'
import useProduct from '~/hooks/components/use-product'

const SummaryImage = ({ slug, sku, forwardSx }) => {
  const { trackProductClicked } = useAnalytics()
  const product = useProduct({ slug })
  const path = sku.includes('|') ? 'bundles' : 'products'
  const url = `/${path}/${slug}/`
  const dispatch = useDispatch()
  const { image } = useProductOverHeadImage({ sku })
  if (!image) {
    return null
  }

  return (
    <Link to={url} onClick={() => dispatch(trackProductClicked({ product }))}>
      <GatsbyImage
        style={{
          overflow: 'visible',
          position: 'absolute',
          left: 0,
          //transform: 'translate(-50%, -50%)',
          height: '120px',
          width: '120px',
          ...forwardSx,
        }}
        imgStyle={{
          objectFit: 'cover',
        }}
        image={image?.gatsbyImageData}
        alt={image?.title}
      />
    </Link>
  )
}

export default SummaryImage
